import { useGateReportStore } from "@/stores/gateReport"

export default (self) => {
  const cameras = useGateReportStore().cameras.map((camera) => ({
    name: `${camera.name} - (${camera.exid})`,
    value: camera.exid,
  }))

  return {
    day: {
      component: "TextFieldSearchFilter",
    },
    cameraExid: {
      name: "camerasExid",
      component: "SelectSearchFilter",
      attributes: {
        items: cameras,
        multiple: true,
      },
    },
    verifiedBy: {
      component: "TextFieldSearchFilter",
    },
    insertedAt: {
      component: "DatePickerRangeSearchFilter",
      attributes: {
        datePickerAttrs: {
          value: [
            self.$moment().subtract(1, "y").format("YYYY-MM-DD"),
            self.formatDate(new Date(), "YYYY-MM-DD"),
          ],
        },
      },
    },
  }
}
