export default [
  {
    text: "Day",
    value: "day",
    sortable: true,
  },
  {
    text: "Camera Exid",
    value: "cameraExid",
    sortable: true,
  },
  {
    text: "Verified By",
    value: "verifiedBy",
    sortable: true,
  },
  {
    text: "Verified At",
    value: "insertedAt",
    sortable: true,
  },
]
